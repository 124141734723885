body {
    /* background-image: url(./whale.jpg); */
    background-color: #000000;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center; 
}

h1 {
    background: rgba(0,0,0,0);
    background-image: url(./whale-racer.png);
    /* background-repeat: no-repeat; */
    /* background-attachment: fixed; */
    background-position: center; 
    height: 500px;
    width: 100%;
    resize-mode: 'stretch';
}

h3 {
    background: #000000;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center; 
    /* height: 800px; */
}

.MuiGrid-spacing-xs-3 > .MuiGrid-item {
    padding: 12px;
    font-family: Righteous-Regular;
}

footer {
    background: #000000;
    color: white;
    position: "fixed";
    left: "0";
    bottom: "0";
}

center {
    color: white;
}